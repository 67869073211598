import React from 'react'
import {Phone, Mail, BookOpen, MapPin} from "react-feather";

import {designSystem} from "./layout";
import styles from './contactSection.module.css'

const ContactSection = ({backgroundColour, borderLeft}) => (
    <>
    <div
        className={styles.contact}
        style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr 1fr 1fr 1fr'
        }}
    >
        <div
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Phone
                style={{
                    paddingRight: designSystem.padding.paddingRight,
                }}
            />
            <a href='tel:02 44735 172'>02 44735 172</a>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Mail
                style={{
                    paddingRight: designSystem.padding.paddingRight
                }}
            />
            <a href='mailto:bodallalavender@gmail.com'>bodallalavender@gmail.com</a>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <BookOpen
                style={{
                    paddingRight: designSystem.padding.paddingRight
                }}
            />
            <span>Open 7 days, 9am to 5pm</span>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <MapPin
                style={{
                    paddingRight: designSystem.padding.paddingRight
                }}
            />
            <span>Princes Highway, Bodalla, look out for the turquoise shed!</span>
        </div>
    </div>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3351.0085364626248!2d150.04758395092256!3d-36.086883980004124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b3e1b677032b6a7%3A0xf804c4998b34f4aa!2sBodalla+Lavender+Shop!5e1!3m2!1sen!2sau!4v1561726559354!5m2!1sen!2sau"
            width="512"
            height="375"
            frameBorder="0"
            allowFullScreen
            title="Map of Bodalla Lavender Shop's location"
        />
    </>
);

export default ContactSection;
import React from "react"
import SEO from "../components/seo"
import styles from "./success.module.css";
import Header from "../components/header";
import Layout, {designSystem} from "../components/layout";
import headerStyles from "../components/header.module.css";
import ContactSection from "../components/contactSection";

const Success = () => {

    return (
        <Layout>
            <div
                className={styles.successContainer}
            >
                <SEO title="Success"/>
                <div
                    className={headerStyles.header}
                >
                    <Header
                        headerTextColour={designSystem.palette.black}
                        backgroundColour={designSystem.palette.white}
                        backgroundColourSmallScreen={designSystem.palette.white}
                        showSmallHeading={true}
                    />
                </div>
                />
                <div
                    className={styles.content}
                >
                    <section
                        className={styles.successMessage}
                    >
                        <h3>Your purchase has been successful</h3>
                        <article>
                            <p>
                                Our lavender pixies are hard at work packing your order and we'll keep you
                                posted
                                as we go.
                            </p>
                            <p>
                                However, if you have any questions, or just want to say hi, please feel free to get in
                                touch.
                            </p>
                        </article>
                        <blockquote>"Thanks for supporting a small Australian business!"</blockquote>
                        <hr
                            style={{
                                width: '100px',
                                height: '1px',
                                backgroundColor: designSystem.palette.mainPurple,
                                margin: '0 auto'
                            }}
                        />
                        <img
                            src={'https://res.cloudinary.com/cleanswelllines/image/upload/v1581726445/bodallaLavender/lavenderShopPixies_gwcdrh.jpg'}
                            alt={'Lavender Pixies'}
                        />
                    </section>

                    <section
                        className={styles.contactSectionContainer}
                    >
                        <ContactSection/>
                    </section>
                </div>
            </div>
        </Layout>
    )
};

export default Success;